import { QRCode } from 'react-qrcode-logo';
import './components.css';

export const QrCode = ({ url }: { url: String }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const qrURL = `${baseUrl}/profile/${url}`;
  return (
    <div className="qrCode">
      <QRCode value={qrURL} size={250}  logoWidth={150} logoHeight={35} logoPadding={5} logoImage={'https://globalwebforce.com/wp-content/uploads/2021/06/cropped-nav-logo.png'} removeQrCodeBehindLogo logoOpacity={.4}/>
      {/* logoImage={'https://globalwebforce.com/wp-content/uploads/2021/06/cropped-nav-logo.png'} */}
    </div>
  )
}

export default QrCode
