const contactDownloader = (userData: any) => {
	const url = `${process.env.REACT_APP_API_BASE_URL}/profile/${userData._id}`
	const link = document.createElement("a")
	link.href = url
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

export default contactDownloader
