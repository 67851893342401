import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


const Home = () => {

  const navigate = useNavigate();

  console.log(localStorage.getItem('uuid'));

  useEffect(() => {
    if (localStorage.getItem('uuid')) {
      navigate(`/profile/${localStorage.getItem('uuid')}`);
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <div>Home</div>
  )
}

export default Home