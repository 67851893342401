import './Pages.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface RegistrationForm {
    email: string;
    password: string;
    [key: string]: string; // Define an index signature to allow for dynamic property access
  }

const form: RegistrationForm = {
  email: '',
  password: '',
};

function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
  const { name, value } = event.target;
  form[name] = value;
}

export const Login = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('uuid')) {
      navigate(`/profile/${localStorage.getItem('uuid')}`);
    } else {
      navigate('/login');
    }
  }, []);


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Validate form fields
    if (form.email && form.password) {
      const Login = async () => {
          const response:any =  await fetch('https://api.aaahh.net/user/login', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(form)
          })

          if(response.status === 200) {
            const data = await response.json()
            localStorage.setItem('uuid', data.user._id);
            console.log(localStorage.getItem('uuid'));
            navigate(`/profile/${localStorage.getItem('uuid')}`);
            
          }else {
            const message = document.getElementById('error-message');
            message?.classList.add('show');

          }
          
      }
      Login();
  
    } else {
      console.log('Form validation failed');
    }
  }


    return (
        <div className="container bgyellow">
            <div className="form-container">                
                <div className="form-control">
                <h2>DigiCard Login</h2>
                    <span id='error-message' 
                    // className="show"
                    >Username or Password is Invalid</span>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" onChange={handleInputChange} />
                        </div>
                    
                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <input type="password" id="password" name="password" onChange={handleInputChange} />
                        </div>
                        <button type="submit">Login</button>
                    </form>
                </div>
            </div>
        </div>
      );
}

export default Login