import './Pages.css';

interface RegistrationForm {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    [key: string]: string; // Define an index signature to allow for dynamic property access
  }

const form: RegistrationForm = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
};

function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
  const { name, value } = event.target;
  form[name] = value;
}

function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
  event.preventDefault();
  // Validate form fields
  if (form.name && form.email && form.password && form.password === form.confirmPassword) {
    // Submit form
    console.log('Submitting form:', form);
  } else {
    console.log('Form validation failed');
  }
}

export const Registration = () => {
    return (
        <div className="container">
            <div className="form-container">
                <div className="spacer"></div>
                <h2 className='center'>Create your DigiCard</h2>

                <div className="form-control">
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" onChange={handleInputChange} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" onChange={handleInputChange} />
                        </div>
                    
                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <input type="password" id="password" name="password" onChange={handleInputChange} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm Password:</label>
                            <input type="password" id="confirmPassword" name="confirmPassword" onChange={handleInputChange} />
                        </div>
                    
                        <button type="submit">Create Account</button>
                    </form>
                </div>
            </div>
        </div>
      );
}

export default Registration