import { BsEnvelope,BsFillTelephoneFill,BsFillCreditCard2FrontFill,BsGeoAlt,BsGlobe,BsBuilding, BsChatRightQuoteFill   } from "react-icons/bs";
import './Variation2.css';
import userInterface from '../../../interfaces/userInterface';
import contactDownloader from '../../../components/helpers';
import QrCode from "../../../components/qrCode";
import PWAPrompt from 'react-ios-pwa-prompt';



interface Props {
  userData: userInterface;
}

export const Variation2 = ({ userData }:Props ) => {



  const avatarURL = process.env.REACT_APP_API_BASE_URL + "/uploads/" + userData?.vCard?.avatar;

  const downloadContact = () => {
    contactDownloader(userData);
  }



  return (
    <>
    <PWAPrompt />
        <div className="header">
          <div className="white_bg full_width">
            <div className="border_right ">
            &nbsp;
            </div>
          </div>

          <div className="logo_container">
            <div className="header-logo">
                <img src="https://globalwebforce.com/wp-content/uploads/2021/06/cropped-nav-logo.png" alt="" />
            </div>
          </div>

          <div className="white_bg full_width">
            <div className="border_left">
            &nbsp;
            </div>
          </div>
        </div>

        {/* Avatar */}

        <div className="min400 full_width avatarContainer">
          <img src={avatarURL} alt="" />
        </div>

        {/* Contact Details */}
        <div className="profile_container">
            <div className="profile" >
                      <h3>{userData.first_name} {userData.last_name}</h3>
                      <p >{userData.vCard?.job_title}</p>

                      <p className='italic bold orange'><span className="rounded-icon"><BsBuilding/></span> {userData.vCard?.company?.name}</p>

                      <div className="horizontal_border">
                        <p>
                        {userData?.shortBio}
                        </p>
                      </div>
                      
                      <p className="italic"><span className="rounded-icon"><BsChatRightQuoteFill/></span>{userData?.pronouns}</p>

                      <div className="contact_wrapper">
                          <p className="smaller-email"><span className="rounded-icon"><BsEnvelope/></span> {userData?.email}</p>
                          <p><span className="rounded-icon"><BsGeoAlt/></span>  {userData.vCard?.address}</p>
                          <p><span className="rounded-icon"><BsFillTelephoneFill/></span>  {userData?.vCard?.phone1}</p>
                          <p><span className="rounded-icon"><BsGlobe/></span>  {userData.vCard?.url}</p><br/>
                      </div>
                      
            </div>
        </div>

        {/* QRCode */}
        <QrCode url={userData?._id} />
        <div className="spacer">&nbsp; </div>


        {/* SaveContactButton */}
        <div className="SaveContactBtn">
                <div className="SaveBtn"><button onClick={downloadContact}><BsFillCreditCard2FrontFill /> Save Contact</button></div>
        </div>
    </>
  )
}

export default Variation2