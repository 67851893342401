import { useEffect, useState } from "react";
import Variation1 from './Variation1/Variation1';
import Variation2 from './Variation2/Variation2';
import userInterface from '../../interfaces/userInterface';
import Registration from "../Registration";
import { useParams } from "react-router-dom";


export const Profile = () => {
    const { id } = useParams();

    const [userData, setUserData] = useState({} as userInterface);
    const [isLoading, setIsLoading] = useState(true);

    console.log(userData);

    useEffect(() => {
        const getData = async () => {
            const response = await fetch(`https://api.aaahh.net/user/${id}`);
            const data = await response.json();
            setUserData(data);
            setIsLoading(false);
        }
        getData();
    },[]);

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <>
        <Variation2 userData={userData} />
            {/* <Registration /> */}
            {/* {userData.last_name == 'Parekh' && <Variation2 userData={userData} />}
            {userData.last_name == null && <Variation2 userData={userData} />} */}
        </>
    )
}

export default Profile